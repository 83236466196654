import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer-section">
            <p>© 2025 Made with ❤️ by Vy Nguyen</p>
        </footer>
    );
};

export default Footer;
